import React, { useState } from "react";
import { Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import "./style.css";

export function BackButton() {
	const navigate = useNavigate();

	return (
		<Button
			className="backButton"
			onClick={() => {
				navigate(-1);
			}}
		>
			&laquo; Back
		</Button>
	);
}

export function HomeButton() {
	const navigate = useNavigate();

	return (
		<Button
			className="homeButton"
			onClick={() => {
				navigate("/", { replace: true });
			}}
		>
			Home
		</Button>
	);
}
