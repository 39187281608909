import React, { useState, useRef, useEffect } from "react";
import { VStack, Text, Center, HStack, Button, Input } from "@chakra-ui/react";
import "./style.css";

import { HomeButton, BackButton } from "./HomeAndBackButtons";

export default function MFF_T(props) {
	const inputBox = useRef();
	const bigBox = useRef();
	const maxTries = 3;

	const maxNum = 100;
	const minNum = 0;

	useEffect(() => {
		pickNewNumbers();
	}, []);

	function getRandomInt() {
		return Math.floor(Math.random() * (maxNum + 1 - minNum)) + minNum;
	}

	const [firstNum, setFirstNum] = useState(0);
	const [secondNum, setSecondNum] = useState(0);
	const [operator, setOperator] = useState("+");
	const [answer, setAnswer] = useState(0);
	const [canPick, setCanPick] = useState(true);
	const [tries, setTries] = useState(0);

	function handleKeyUp(e) {
		if (e.key === "Enter") {
			checkRight();
		}
	}

	function pickNewNumbers() {
		var num1 = getRandomInt();
		var num2 = 10;
		inputBox.current.value = "";
		inputBox.current.focus();
		setTries(0);

		var operatorBool = Math.round(Math.random()); //1 is +, 0 is -
		if (operatorBool) {
			setOperator("+");
			setFirstNum(num1);
			setSecondNum(num2);
			setAnswer(num1 + num2);
			if (num1 + num2 > 100) pickNewNumbers();
		} else {
			setOperator("-");
			if (num1 < 10) {
				num1 = num1 + 10;
			}
			setFirstNum(num1);
			setSecondNum(num2);
			setAnswer(num1 - num2);
		}
		setCanPick(true);
	}

	function setRightIndicator(aRef) {
		aRef.current.style.outlineStyle = "solid";
		aRef.current.style.outlineColor = "green";
		aRef.current.style.outlineOffset = "0px";
		aRef.current.style.outlineWidth = "10px";
	}

	function setWrongIndicator(aRef) {
		aRef.current.style.outlineStyle = "solid";
		aRef.current.style.outlineColor = "red";
		aRef.current.style.outlineOffset = "0px";
		aRef.current.style.outlineWidth = "10px";
	}

	function resetIndicator(aRef) {
		aRef.current.style.outlineStyle = "none";
	}

	function checkRight() {
		setCanPick(false);
		var submittedAns = inputBox.current.value;
		if (submittedAns == answer) {
			setRightIndicator(inputBox);
			setTimeout(() => {
				resetIndicator(inputBox);
				pickNewNumbers();
			}, 1500);
		} else {
			setTries(tries + 1);
			setWrongIndicator(inputBox);
			if (tries + 1 >= maxTries) {
				setRightIndicator(bigBox);
				setTimeout(() => {
					resetIndicator(bigBox);
					pickNewNumbers();
				}, 1500);
			}
			setTimeout(() => {
				resetIndicator(inputBox);
				inputBox.current.value = "";
				inputBox.current.focus();
				setCanPick(true);
			}, 1500);
		}
	}

	return (
		<>
			<BackButton />
			<HomeButton />
			<Center pt="50px">
				<VStack>
					<Text className="mainTitle">Solve the problem</Text>
					<HStack spacing="40px">
						<Text className="gameTextBox" ref={bigBox}>
							<Center w="100%" h="100%">
								{firstNum} {operator} {secondNum} ={" "}
								{tries < maxTries ? "" : answer}
							</Center>
						</Text>
					</HStack>
					<Input
						className="gameInputBox"
						ref={inputBox}
						onKeyUp={handleKeyUp}
					></Input>
					<Button
						className="gameSubmitButton"
						isDisabled={!canPick}
						onClick={checkRight}
					>
						Check Answer
					</Button>
				</VStack>
			</Center>
		</>
	);
}
