import React, { useState, useRef, useEffect } from "react";
import { VStack, Text, Center, HStack, Button, Image } from "@chakra-ui/react";
import "./style.css";

import { HomeButton, BackButton } from "./HomeAndBackButtons";
import speakerpng from "./images/1608454_up_volume_icon.png";
import { wordList } from "./NWF_Wordlist";

export default function NWF(props) {
	const [word1, setWord1] = useState("");
	const [word2, setWord2] = useState("");
	const [word3, setWord3] = useState("");
	const [answer, setAnswer] = useState("");
	const [canPick, setCanPick] = useState(true);

	const leftBtn = useRef();
	const midBtn = useRef();
	const rightBtn = useRef();

	useEffect(() => {
		pickNewWords();
	}, []);

	function pickNewWords() {
		setCanPick(true);
		let random = wordList["words"].sort(() => 0.5 - Math.random()).slice(0, 3);
		setWord1(random[0]);
		setWord2(random[1]);
		setWord3(random[2]);

		const rightAnswer = Math.floor(Math.random() * 3);
		setAnswer(random[rightAnswer]);
	}

	function checkRight(aWord, aRef) {
		setCanPick(false);
		if (aWord == answer) {
			setRightIndicator(aRef);
		} else {
			setWrongIndicator(aRef);
		}

		setTimeout(() => {
			resetIndicator(aRef);
			pickNewWords();
		}, 3000);
	}

	function setRightIndicator(aRef) {
		aRef.current.style.outlineStyle = "solid";
		aRef.current.style.outlineColor = "green";
		aRef.current.style.outlineOffset = "0px";
		aRef.current.style.outlineWidth = "10px";
	}

	function setWrongIndicator(aRef) {
		aRef.current.style.outlineStyle = "solid";
		aRef.current.style.outlineColor = "red";
		aRef.current.style.outlineOffset = "0px";
		aRef.current.style.outlineWidth = "10px";
	}

	function resetIndicator(aRef) {
		aRef.current.style.outlineStyle = "none";
	}
	async function sleep(seconds) {
		return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
	}
	return (
		<>
			<BackButton />
			<HomeButton />
			<Center pt="50px">
				<VStack spacing="10px">
					<Text className="mainTitle">Listen and pick the right word</Text>
					<Center>
						<Button
							className="voiceButton"
							onClick={() => {
								var msg = new SpeechSynthesisUtterance();
								msg.rate = 0.75;
								msg.text = answer;
								window.speechSynthesis.speak(msg);
							}}
							isDisabled={!canPick}
						>
							<Image
								w="100%"
								h="100%"
								objectFit="contain"
								src={speakerpng}
							></Image>
						</Button>
					</Center>
					<HStack spacing="40px">
						<Button
							ref={leftBtn}
							className="gameButtons"
							onClick={() => {
								checkRight(word1, leftBtn);
							}}
							isDisabled={!canPick}
						>
							{word1}
						</Button>
						<Button
							ref={midBtn}
							className="gameButtons"
							onClick={() => {
								checkRight(word2, midBtn);
							}}
							isDisabled={!canPick}
						>
							{word2}
						</Button>
						<Button
							ref={rightBtn}
							className="gameButtons"
							onClick={() => {
								checkRight(word3, rightBtn);
							}}
							isDisabled={!canPick}
						>
							{word3}
						</Button>
					</HStack>
				</VStack>
			</Center>
		</>
	);
}
