const wordList = {
	words: [
		"I",
		"a",
		"about",
		"after",
		"again",
		"all",
		"always",
		"am",
		"an",
		"and",
		"another",
		"any",
		"are",
		"around",
		"as",
		"ask",
		"at",
		"ate",
		"away",
		"be",
		"because",
		"been",
		"before",
		"best",
		"better",
		"big",
		"black",
		"blue",
		"both",
		"bring",
		"brown",
		"but",
		"buy",
		"by",
		"call",
		"called",
		"came",
		"can",
		"carry",
		"clean",
		"cold",
		"come",
		"could",
		"cut",
		"day",
		"did",
		"different",
		"do",
		"does",
		"done",
		"don’t",
		"down",
		"draw",
		"drink",
		"each",
		"eat",
		"eight",
		"even",
		"every",
		"fall",
		"far",
		"fast",
		"find",
		"first",
		"five",
		"fly",
		"for",
		"found",
		"four",
		"from",
		"full",
		"funny",
		"gave",
		"get",
		"give",
		"go",
		"goes",
		"going",
		"good",
		"got",
		"green",
		"grow",
		"had",
		"has",
		"have",
		"he",
		"help",
		"her",
		"here",
		"him",
		"his",
		"hold",
		"hot",
		"how",
		"hurt",
		"if",
		"in",
		"into",
		"is",
		"it",
		"its",
		"jump",
		"just",
		"keep",
		"kind",
		"know",
		"laugh",
		"let",
		"light",
		"like",
		"little",
		"live",
		"long",
		"look",
		"made",
		"make",
		"many",
		"may",
		"me",
		"more",
		"most",
		"much",
		"must",
		"my",
		"myself",
		"never",
		"new",
		"no",
		"not",
		"now",
		"number",
		"of",
		"off",
		"oil",
		"old",
		"on",
		"once",
		"one",
		"only",
		"open",
		"or",
		"other",
		"our",
		"out",
		"over",
		"own",
		"part",
		"people",
		"pick",
		"picture",
		"play",
		"please",
		"pretty",
		"pull",
		"put",
		"ran",
		"read",
		"red",
		"ride",
		"right",
		"round",
		"run",
		"said",
		"saw",
		"say",
		"says",
		"see",
		"seven",
		"shall",
		"she",
		"should",
		"show",
		"sing",
		"sit",
		"six",
		"sleep",
		"small",
		"so",
		"some",
		"soon",
		"start",
		"stop",
		"take",
		"tell",
		"ten",
		"than",
		"thank",
		"that",
		"the",
		"their",
		"them",
		"then",
		"there",
		"these",
		"they",
		"think",
		"this",
		"those",
		"three",
		"through",
		"time",
		"to",
		"today",
		"together",
		"tomorrow",
		"too",
		"try",
		"two",
		"under",
		"up",
		"upon",
		"us",
		"use",
		"very",
		"walk",
		"want",
		"warm",
		"was",
		"wash",
		"water",
		"way",
		"we",
		"well",
		"went",
		"were",
		"what",
		"when",
		"where",
		"which",
		"white",
		"who",
		"why",
		"will",
		"wish",
		"with",
		"word",
		"words",
		"work",
		"would",
		"write",
		"yellow",
		"yes",
		"yesterday",
		"you",
		"your",
	],
};
export { wordList };

// words: [
// 	"the",
// 	"of",
// 	"and",
// 	"a",
// 	"to",
// 	"in",
// 	"is",
// 	"you",
// 	"that",
// 	"it",
// 	"he",
// 	"was",
// 	"for",
// 	"on",
// 	"are",
// 	"as",
// 	"with",
// 	"his",
// 	"they",
// 	"I",
// 	"at",
// 	"be",
// 	"this",
// 	"have",
// 	"from",
// 	"or",
// 	"are",
// 	"had",
// 	"by",
// 	"words",
// 	"but",
// 	"not",
// 	"what",
// 	"all",
// 	"were",
// 	"we",
// 	"when",
// 	"your",
// 	"can",
// 	"said",
// 	"there",
// 	"use",
// 	"an",
// 	"each",
// 	"which",
// 	"she",
// 	"so",
// 	"how",
// 	"their",
// 	"if",
// 	"will",
// 	"up",
// 	"other",
// 	"about",
// 	"out",
// 	"many",
// 	"then",
// 	"them",
// 	"these",
// 	"so",
// 	"some",
// 	"her",
// 	"would",
// 	"make",
// 	"like",
// 	"him",
// 	"into",
// 	"time",
// 	"has",
// 	"look",
// 	"two",
// 	"more",
// 	"write",
// 	"go",
// 	"see",
// 	"number",
// 	"no",
// 	"way",
// 	"could",
// 	"people",
// 	"my",
// 	"than",
// 	"first",
// 	"water",
// 	"been",
// 	"called",
// 	"who",
// 	"oil",
// 	"sit",
// 	"now",
// 	"find",
// 	"long",
// 	"down",
// 	"day",
// 	"did",
// 	"get",
// 	"come",
// 	"made",
// 	"may",
// 	"part",
// 	"should",
// 	"most",
// 	"different",
// 	"the",
// 	"to",
// 	"and",
// 	"he",
// 	"a",
// 	"I",
// 	"you",
// 	"it",
// 	"of",
// 	"in",
// 	"was",
// 	"said",
// 	"his",
// 	"that",
// 	"she",
// 	"for",
// 	"on",
// 	"they",
// 	"but",
// 	"had",
// 	"at",
// 	"him",
// 	"with",
// 	"up",
// 	"all",
// 	"look",
// 	"is",
// 	"her",
// 	"there",
// 	"some",
// 	"out",
// 	"as",
// 	"be",
// 	"have",
// 	"go",
// 	"we",
// 	"am",
// 	"then",
// 	"little",
// 	"down",
// 	"do",
// 	"can",
// 	"could",
// 	"when",
// 	"did",
// 	"what",
// 	"so",
// 	"see",
// 	"not",
// 	"were",
// 	"get",
// 	"them",
// 	"like",
// 	"one",
// 	"this",
// 	"my",
// 	"would",
// 	"me",
// 	"will",
// 	"yes",
// 	"big",
// 	"went",
// 	"are",
// 	"come",
// 	"if",
// 	"now",
// 	"long",
// 	"no",
// 	"came",
// 	"ask",
// 	"very",
// 	"an",
// 	"over",
// 	"your",
// 	"its",
// 	"ride",
// 	"into",
// 	"just",
// 	"blue",
// 	"red",
// 	"from",
// 	"good",
// 	"any",
// 	"about",
// 	"around",
// 	"want",
// 	"don’t",
// 	"how",
// 	"know",
// 	"right",
// 	"put",
// 	"too",
// 	"got",
// 	"take",
// 	"where",
// 	"every",
// 	"pretty",
// 	"jump",
// 	"green",
// 	"four",
// 	"away",
// 	"old",
// 	"by",
// 	"their",
// 	"here",
// 	"saw",
// 	"call",
// 	"after",
// 	"well",
// 	"think",
// 	"ran",
// 	"let",
// 	"help",
// 	"make",
// 	"going",
// 	"sleep",
// 	"brown",
// 	"yellow",
// 	"five",
// 	"six",
// 	"walk",
// 	"two",
// 	"or",
// 	"before",
// 	"eat",
// 	"again",
// 	"play",
// 	"who",
// 	"been",
// 	"may",
// 	"stop",
// 	"off",
// 	"never",
// 	"seven",
// 	"eight",
// 	"cold",
// 	"today",
// 	"fly",
// 	"myself",
// 	"round",
// 	"tell",
// 	"much",
// 	"keep",
// 	"give",
// 	"work",
// 	"first",
// 	"try",
// 	"new",
// 	"must",
// 	"start",
// 	"black",
// 	"white",
// 	"ten",
// 	"does",
// 	"bring",
// 	"goes",
// 	"write",
// 	"always",
// 	"drink",
// 	"once",
// 	"soon",
// 	"made",
// 	"run",
// 	"gave",
// 	"open",
// 	"has",
// 	"find",
// 	"only",
// 	"us",
// 	"three",
// 	"our",
// 	"better",
// 	"hold",
// 	"buy",
// 	"funny",
// 	"warm",
// 	"ate",
// 	"full",
// 	"those",
// 	"done",
// 	"use",
// 	"fast",
// 	"say",
// 	"light",
// 	"pick",
// 	"hurt",
// 	"pull",
// 	"cut",
// 	"kind",
// 	"both",
// 	"sit",
// 	"which",
// 	"fall",
// 	"carry",
// 	"small",
// 	"under",
// 	"read",
// 	"why",
// 	"own",
// 	"found",
// 	"wash",
// 	"show",
// 	"hot",
// 	"because",
// 	"far",
// 	"live",
// 	"draw",
// 	"clean",
// 	"grow",
// 	"best",
// 	"upon",
// 	"these",
// 	"sing",
// 	"together",
// 	"please",
// 	"thank",
// 	"wish",
// 	"many",
// 	"shall",
// 	"laugh",
// 	"through",
// 	"another",
// 	"even",
// 	"the",
// 	"of",
// 	"and",
// 	"a",
// 	"to",
// 	"in",
// 	"is",
// 	"you",
// 	"that",
// 	"it",
// 	"he",
// 	"was",
// 	"for",
// 	"on",
// 	"are",
// 	"as",
// 	"with",
// 	"his",
// 	"they",
// 	"I",
// 	"at",
// 	"be",
// 	"this",
// 	"have",
// 	"from",
// 	"or",
// 	"one",
// 	"had",
// 	"by",
// 	"words",
// 	"but",
// 	"not",
// 	"what",
// 	"all",
// 	"were",
// 	"we",
// 	"when",
// 	"your",
// 	"can",
// 	"said",
// 	"there",
// 	"use",
// 	"an",
// 	"each",
// 	"which",
// 	"she",
// 	"do",
// 	"how",
// 	"their",
// 	"if",
// 	"will",
// 	"up",
// 	"other",
// 	"about",
// 	"out",
// 	"many",
// 	"then",
// 	"them",
// 	"these",
// 	"so",
// 	"some",
// 	"her",
// 	"would",
// 	"make",
// 	"like",
// 	"him",
// 	"into",
// 	"time",
// 	"has",
// 	"look",
// 	"two",
// 	"more",
// 	"write",
// 	"go",
// 	"see",
// 	"number",
// 	"no",
// 	"way",
// 	"could",
// 	"people",
// 	"my",
// 	"than",
// 	"first",
// 	"water",
// 	"been",
// 	"called",
// 	"who",
// 	"am",
// 	"its",
// 	"now",
// 	"find",
// 	"long",
// 	"down",
// 	"day",
// 	"did",
// 	"get",
// 	"come",
// 	"made",
// 	"may",
// 	"part",
// 	"a",
// 	"I",
// 	"no",
// 	"so",
// 	"of",
// 	"is",
// 	"to",
// 	"all",
// 	"some",
// 	"from",
// 	"word",
// 	"are",
// 	"have",
// 	"were",
// 	"one",
// 	"once",
// 	"do",
// 	"two",
// 	"the",
// 	"who",
// 	"said",
// 	"says",
// 	"was",
// 	"when",
// 	"why",
// 	"where",
// 	"what",
// 	"which",
// 	"here",
// 	"there",
// 	"he",
// 	"she",
// 	"be",
// 	"me",
// 	"we",
// 	"they",
// 	"their",
// 	"by",
// 	"my",
// 	"you",
// 	"your",
// 	"because",
// 	"could",
// 	"should",
// 	"would",
// 	"down",
// 	"yesterday",
// 	"today",
// 	"tomorrow",
// 	"how",
// 	"picture",
// ],
