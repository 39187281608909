import React, { useState, useRef, useEffect } from "react";
import { VStack, Text, Center, Button, Image } from "@chakra-ui/react";
import "./style.css";

import { HomeButton, BackButton } from "./HomeAndBackButtons";
import { wordList } from "./WRF_Wordlist";

import mic from "./images/9025831_microphone_icon.png";

export default function WRF(props) {
	const bigBox = useRef();

	const [word, setWord] = useState("");
	const [wordHeard, setWordHeard] = useState("");
	const [canPick, setCanPick] = useState(true);

	const GetSpeech = () => {
		const SpeechRecognition =
			window.SpeechRecognition || window.webkitSpeechRecognition;

		let recognition = new SpeechRecognition();
		recognition.onstart = () => {
			setCanPick(false);
			setWordHeard("Listening...");
			console.log("starting listening, speak in microphone");
		};
		recognition.onspeechend = () => {
			console.log("stopped listening");
			recognition.stop();
		};
		recognition.onresult = (result) => {
			setWordHeard(result.results[0][0].transcript);
			checkRight(result.results[0][0].transcript);
		};

		recognition.start();
	};

	useEffect(() => {
		pickNewWord();
	}, []);

	function pickNewWord() {
		setCanPick(true);

		let random = wordList["words"].sort(() => 0.5 - Math.random()).slice(0, 1);
		setWord(random[0]);
		setCanPick(true);
		setWordHeard("");
	}

	function setRightIndicator(aRef) {
		aRef.current.style.outlineStyle = "solid";
		aRef.current.style.outlineColor = "green";
		aRef.current.style.outlineOffset = "0px";
		aRef.current.style.outlineWidth = "10px";
	}

	function setWrongIndicator(aRef) {
		aRef.current.style.outlineStyle = "solid";
		aRef.current.style.outlineColor = "red";
		aRef.current.style.outlineOffset = "0px";
		aRef.current.style.outlineWidth = "10px";
	}

	function resetIndicator(aRef) {
		aRef.current.style.outlineStyle = "none";
	}

	function checkRight(aWord) {
		if (aWord == word) {
			setRightIndicator(bigBox);
		} else {
			setWrongIndicator(bigBox);
		}
		setTimeout(() => {
			resetIndicator(bigBox);
			pickNewWord();
			setCanPick(true);
		}, 3000);
	}

	return (
		<>
			<BackButton />
			<HomeButton />
			<Center pt="50px">
				<VStack>
					<Text className="mainTitle">Say the word</Text>
					<Button ref={bigBox} className="gameButtons" isDisabled={!canPick}>
						{word}
						<br />
						{wordHeard}
					</Button>
					<Button
						className="voiceButton"
						onClick={GetSpeech}
						isDisabled={!canPick}
					>
						<Image w="100%" h="100%" objectFit="contain" src={mic}></Image>
					</Button>
				</VStack>
			</Center>
		</>
	);
}
