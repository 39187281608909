const wordList = {
	words: [
		"after",
		"arch",
		"arm",
		"art",
		"as",
		"ash",
		"bad",
		"bag",
		"ban",
		"bar",
		"bark",
		"barn",
		"bash",
		"bat",
		"bath",
		"bed",
		"beg",
		"bench",
		"bet",
		"bib",
		"bid",
		"big",
		"bin",
		"bit",
		"blush",
		"bob",
		"bog",
		"booth",
		"born",
		"bud",
		"bug",
		"bum",
		"bun",
		"bunch",
		"bus",
		"but",
		"cab",
		"can",
		"cap",
		"car",
		"card",
		"cart",
		"cash",
		"cat",
		"champ",
		"chant",
		"chap",
		"charm",
		"chart",
		"chase",
		"chat",
		"check",
		"cheek",
		"chess",
		"chest",
		"chick",
		"chill",
		"chime",
		"chimp",
		"chin",
		"chip",
		"choke",
		"chop",
		"chug",
		"clerk",
		"cloth",
		"cob",
		"cog",
		"cop",
		"cord",
		"cot",
		"couch",
		"cub",
		"cud",
		"cup",
		"cut",
		"dad",
		"dark",
		"dash",
		"den",
		"did",
		"dig",
		"dim",
		"din",
		"dip",
		"dish",
		"dog",
		"dot",
		"dub",
		"dud",
		"dug",
		"dun",
		"fan",
		"far",
		"fat",
		"fed",
		"fern",
		"fib",
		"fig",
		"fin",
		"fish",
		"fit",
		"flesh",
		"fog",
		"for",
		"fork",
		"form",
		"forth",
		"fun",
		"gag",
		"gal",
		"gap",
		"gem",
		"get",
		"gig",
		"gob",
		"got",
		"gum",
		"gun",
		"gut",
		"had",
		"ham",
		"hard",
		"hat",
		"hem",
		"hen",
		"her",
		"herd",
		"hid",
		"him",
		"hip",
		"hit",
		"hog",
		"hop",
		"horn",
		"hot",
		"hub",
		"hug",
		"hum",
		"hush",
		"hut",
		"inch",
		"jam",
		"jar",
		"jet",
		"jig",
		"job",
		"jog",
		"jot",
		"jug",
		"jut",
		"keg",
		"kid",
		"kin",
		"kit",
		"lab",
		"lad",
		"lag",
		"lap",
		"led",
		"leg",
		"let",
		"lid",
		"lip",
		"lit",
		"log",
		"lot",
		"lug",
		"lunch",
		"mad",
		"man",
		"map",
		"march",
		"mart",
		"mash",
		"mat",
		"math",
		"men",
		"mesh",
		"met",
		"mob",
		"mom",
		"mop",
		"moth",
		"mouth",
		"much",
		"mud",
		"mug",
		"mum",
		"nag",
		"nap",
		"net",
		"nip",
		"north",
		"not",
		"nub",
		"nun",
		"nut",
		"ouch",
		"pad",
		"pan",
		"park",
		"pat",
		"path",
		"peg",
		"pen",
		"pep",
		"perch",
		"pet",
		"pig",
		"pin",
		"pinch",
		"pit",
		"plush",
		"pom",
		"pop",
		"porch",
		"pork",
		"port",
		"posh",
		"pot",
		"pouch",
		"pug",
		"pun",
		"punch",
		"pup",
		"rag",
		"ram",
		"ran",
		"rap",
		"rash",
		"rat",
		"red",
		"rib",
		"rich",
		"rid",
		"rig",
		"rim",
		"rip",
		"rob",
		"rod",
		"rot",
		"rub",
		"rug",
		"run",
		"rush",
		"rut",
		"sad",
		"sag",
		"sap",
		"sat",
		"set",
		"shack",
		"shade",
		"shaft",
		"shake",
		"shame",
		"shape",
		"shark",
		"sharp",
		"shave",
		"she",
		"shed",
		"sheep",
		"sheet",
		"shelf",
		"shell",
		"shop",
		"short",
		"shot",
		"shred",
		"shrug",
		"sin",
		"sip",
		"sit",
		"sloth",
		"smart",
		"smash",
		"sob",
		"sod",
		"sort",
		"south",
		"speech",
		"splash",
		"star",
		"start",
		"sub",
		"such",
		"sum",
		"sun",
		"tab",
		"tad",
		"tag",
		"tan",
		"tap",
		"teeth",
		"ten",
		"tenth",
		"term",
		"than",
		"that",
		"theft",
		"them",
		"then",
		"these",
		"thick",
		"thin",
		"thing",
		"this",
		"thorn",
		"those",
		"three",
		"thrill",
		"throb",
		"throne",
		"tin",
		"tip",
		"tooth",
		"top",
		"torch",
		"torn",
		"tot",
		"trash",
		"tub",
		"tug",
		"van",
		"vat",
		"verb",
		"vet",
		"wag",
		"web",
		"wed",
		"wet",
		"which",
		"wig",
		"win",
		"wish",
		"wit",
		"with",
		"yarn",
		"yes",
		"yet",
		"yip",
		"zip",
	],
};
export { wordList };

// words: [
//     "bad",
//     "dad",
//     "had",
//     "lad",
//     "mad",
//     "pad",
//     "sad",
//     "tad",
//     "bag",
//     "gag",
//     "lag",
//     "nag",
//     "rag",
//     "sag",
//     "tag",
//     "wag",
//     "ban",
//     "can",
//     "fan",
//     "man",
//     "pan",
//     "ran",
//     "tan",
//     "van",
//     "cap",
//     "gap",
//     "lap",
//     "map",
//     "nap",
//     "rap",
//     "sap",
//     "tap",
//     "bat",
//     "cat",
//     "fat",
//     "hat",
//     "mat",
//     "pat",
//     "rat",
//     "sat",
//     "vat",
//     "cab",
//     "lab",
//     "tab",
//     "gal",
//     "ham",
//     "jam",
//     "ram",
//     "as",
//     "bed",
//     "fed",
//     "led",
//     "red",
//     "wed",
//     "beg",
//     "keg",
//     "leg",
//     "peg",
//     "den",
//     "hen",
//     "men",
//     "pen",
//     "ten",
//     "bet",
//     "get",
//     "jet",
//     "let",
//     "met",
//     "net",
//     "pet",
//     "set",
//     "vet",
//     "wet",
//     "yet",
//     "web",
//     "gem",
//     "hem",
//     "pep",
//     "yes",
//     "bid",
//     "did",
//     "hid",
//     "kid",
//     "lid",
//     "rid",
//     "big",
//     "dig",
//     "fig",
//     "gig",
//     "jig",
//     "pig",
//     "rig",
//     "wig",
//     "bin",
//     "din",
//     "fin",
//     "kin",
//     "pin",
//     "sin",
//     "tin",
//     "win",
//     "dip",
//     "hip",
//     "lip",
//     "nip",
//     "rip",
//     "sip",
//     "tip",
//     "yip",
//     "zip",
//     "bit",
//     "fit",
//     "hit",
//     "kit",
//     "lit",
//     "pit",
//     "sit",
//     "wit",
//     "bib",
//     "fib",
//     "rib",
//     "dim",
//     "him",
//     "rim",
//     "bob",
//     "cob",
//     "gob",
//     "job",
//     "mob",
//     "rob",
//     "sob",
//     "bog",
//     "cog",
//     "dog",
//     "fog",
//     "hog",
//     "jog",
//     "log",
//     "cop",
//     "hop",
//     "mop",
//     "pop",
//     "top",
//     "cot",
//     "dot",
//     "got",
//     "hot",
//     "jot",
//     "lot",
//     "not",
//     "pot",
//     "rot",
//     "tot",
//     "rod",
//     "sod",
//     "mom",
//     "pom",
//     "cub",
//     "dub",
//     "hub",
//     "nub",
//     "rub",
//     "sub",
//     "tub",
//     "bug",
//     "dug",
//     "hug",
//     "jug",
//     "lug",
//     "mug",
//     "pug",
//     "rug",
//     "tug",
//     "bum",
//     "gum",
//     "hum",
//     "mum",
//     "sum",
//     "bun",
//     "dun",
//     "fun",
//     "gun",
//     "nun",
//     "pun",
//     "run",
//     "sun",
//     "but",
//     "cut",
//     "gut",
//     "hut",
//     "jut",
//     "nut",
//     "rut",
//     "bud",
//     "cud",
//     "dud",
//     "mud",
//     "pup",
//     "cup",
//     "bus",
//     "shack",
//     "shade",
//     "shaft",
//     "shake",
//     "shame",
//     "shape",
//     "sharp",
//     "shave",
//     "she",
//     "shed",
//     "sheep",
//     "sheet",
//     "shelf",
//     "shell",
//     "bash",
//     "blush",
//     "cash",
//     "dash",
//     "dish",
//     "fish",
//     "flesh",
//     "hush",
//     "mash",
//     "mesh",
//     "rash",
//     "rush",
//     "splash",
//     "wish",
//     "ash",
//     "shot",
//     "shop",
//     "posh",
//     "trash",
//     "smash",
//     "plush",
//     "shred",
//     "shrug",
//     "chap",
//     "chat",
//     "chip",
//     "chin",
//     "chop",
//     "chug",
//     "champ",
//     "chant",
//     "charm",
//     "chart",
//     "chase",
//     "check",
//     "cheek",
//     "chess",
//     "chest",
//     "chick",
//     "chill",
//     "chimp",
//     "chime",
//     "choke",
//     "arch",
//     "inch",
//     "much",
//     "ouch",
//     "such",
//     "rich",
//     "bench",
//     "bunch",
//     "couch",
//     "lunch",
//     "march",
//     "perch",
//     "pinch",
//     "porch",
//     "pouch",
//     "punch",
//     "torch",
//     "which",
//     "speech",
//     "than",
//     "that",
//     "this",
//     "theft",
//     "them",
//     "then",
//     "thick",
//     "thin",
//     "thing",
//     "these",
//     "than",
//     "thorn",
//     "bath",
//     "path",
//     "moth",
//     "math",
//     "cloth",
//     "with",
//     "those",
//     "three",
//     "throb",
//     "thrill",
//     "throne",
//     "booth",
//     "forth",
//     "mouth",
//     "north",
//     "sloth",
//     "south",
//     "teeth",
//     "tenth",
//     "tooth",
//     "far",
//     "art",
//     "mart",
//     "bark",
//     "star",
//     "car",
//     "hard",
//     "yarn",
//     "shark",
//     "arm",
//     "cart",
//     "jar",
//     "park",
//     "march",
//     "dark",
//     "chart",
//     "start",
//     "card",
//     "charm",
//     "barn",
//     "bar",
//     "smart",
//     "born",
//     "fork",
//     "short",
//     "thorn",
//     "horn",
//     "form",
//     "north",
//     "for",
//     "torn",
//     "sort",
//     "cord",
//     "torch",
//     "pork",
//     "port",
//     "her",
//     "term",
//     "herd",
//     "fern",
//     "clerk",
//     "perch",
//     "after",
//     "verb",
// ],
