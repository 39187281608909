import React from "react";
import "./style.css";

import { VStack, Button, Text, Center, HStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { HomeButton, BackButton } from "./HomeAndBackButtons";

export default function AIMSWebMath(props) {
	const navigate = useNavigate();
	return (
		<>
			<BackButton />
			<HomeButton />

			<Center mt="10px">
				<Text className="mainTitle">AIMSWeb+ Math</Text>
			</Center>
			<VStack w="100%" justifyContent="space-between" spacing="20px">
				<HStack spacing="20px" h="50%" justifyContent="space-between">
					<Center>
						<Button
							className="mainButtons"
							onClick={() => {
								navigate("ncf");
							}}
						>
							NCF
						</Button>
					</Center>
					<Center>
						<Button
							className="mainButtons"
							onClick={() => {
								navigate("mff-1d");
							}}
						>
							MFF-1D
						</Button>
					</Center>
				</HStack>
				<HStack spacing="20px" h="50%" justifyContent="space-between">
					<Center>
						<Button
							className="mainButtons"
							onClick={() => {
								navigate("mff-t");
							}}
						>
							MFF-T
						</Button>
					</Center>
				</HStack>
			</VStack>
		</>
	);
}
