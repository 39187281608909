import React, { useState, useRef } from "react";
import { VStack, Text, Center, HStack, Button } from "@chakra-ui/react";
import "./style.css";

import { HomeButton, BackButton } from "./HomeAndBackButtons";

export default function NCF(props) {
	const leftBtn = useRef();
	const rightBtn = useRef();

	const maxNum = 120;
	const minNum = 0;

	function getRandomInt() {
		return Math.floor(Math.random() * (maxNum + 1 - minNum)) + minNum;
	}

	const [randNum1, setRandNum1] = useState(getRandomInt());
	const [randNum2, setRandNum2] = useState(getRandomInt());
	const [canPick, setCanPick] = useState(true);

	function pickNewNumbers() {
		var num1 = getRandomInt();
		var num2 = getRandomInt();
		while (num2 === num1) {
			num2 = getRandomInt();
		}
		setRandNum1(num1);
		setRandNum2(num2);

		setCanPick(true);
	}

	function setRightIndicator(aRef) {
		aRef.current.style.outlineStyle = "solid";
		aRef.current.style.outlineColor = "green";
		aRef.current.style.outlineOffset = "0px";
		aRef.current.style.outlineWidth = "10px";
	}

	function setWrongIndicator(aRef) {
		aRef.current.style.outlineStyle = "solid";
		aRef.current.style.outlineColor = "red";
		aRef.current.style.outlineOffset = "0px";
		aRef.current.style.outlineWidth = "10px";
	}

	function resetIndicator(aRef) {
		aRef.current.style.outlineStyle = "none";
	}

	function checkRight(aNum) {
		setCanPick(false);
		if (aNum === 1) {
			if (randNum1 >= randNum2) {
				setRightIndicator(leftBtn);
				setTimeout(() => {
					resetIndicator(leftBtn);
					pickNewNumbers();
				}, 1500);
			} else {
				setWrongIndicator(leftBtn);
				setTimeout(() => {
					resetIndicator(leftBtn);
					pickNewNumbers();
				}, 1500);
			}
		} else {
			if (randNum2 >= randNum1) {
				setRightIndicator(rightBtn);
				setTimeout(() => {
					resetIndicator(rightBtn);
					pickNewNumbers();
				}, 1500);
			} else {
				setWrongIndicator(rightBtn);
				setTimeout(() => {
					resetIndicator(rightBtn);
					pickNewNumbers();
				}, 1500);
			}
		}
	}

	return (
		<>
			<BackButton />
			<HomeButton />
			<Center pt="50px">
				<VStack>
					<Text className="mainTitle">Which is bigger?</Text>
					<HStack spacing="40px">
						<Button
							ref={leftBtn}
							className="gameButtons"
							onClick={() => {
								checkRight(1);
							}}
							isDisabled={!canPick}
						>
							{randNum1}
						</Button>
						<Button
							ref={rightBtn}
							className="gameButtons"
							onClick={() => {
								checkRight(2);
							}}
							isDisabled={!canPick}
						>
							{randNum2}
						</Button>
					</HStack>
				</VStack>
			</Center>
		</>
	);
}
