const wordList = {
	words: [
		"ac",
		"ad",
		"aj",
		"ak",
		"av",
		"bal",
		"bas",
		"bef",
		"bel",
		"bis",
		"dek",
		"dev",
		"doc",
		"doj",
		"dok",
		"dop",
		"doz",
		"duz",
		"eb",
		"ec",
		"el",
		"em",
		"ep",
		"et",
		"faj",
		"fav",
		"feb",
		"feg",
		"foj",
		"hal",
		"hic",
		"hiz",
		"hod",
		"hon",
		"hos",
		"huf",
		"huv",
		"ib",
		"id",
		"ig",
		"ik",
		"jaf",
		"jaj",
		"jeb",
		"jec",
		"jej",
		"jit",
		"jok",
		"jos",
		"joz",
		"jud",
		"kab",
		"kaj",
		"kam",
		"ked",
		"kef",
		"kes",
		"kic",
		"kick",
		"kov",
		"kub",
		"laj",
		"len",
		"lod",
		"loj",
		"los",
		"luj",
		"mig",
		"min",
		"nej",
		"nek",
		"nen",
		"nov",
		"nuf",
		"nuk",
		"ol",
		"os",
		"pac",
		"pol",
		"pov",
		"puf",
		"ral",
		"rec",
		"res",
		"ril",
		"riz",
		"ros",
		"ruz",
		"seb",
		"sej",
		"sib",
		"sij",
		"sok",
		"sov",
		"suv",
		"tef",
		"tic",
		"tik",
		"tov",
		"tum",
		"uc",
		"uf",
		"ug",
		"ul",
		"um",
		"un",
		"ut",
		"vad",
		"ven",
		"vep",
		"ves",
		"vib",
		"vif",
		"vos",
		"vov",
		"vus",
		"vut",
		"waf",
		"wan",
		"wem",
		"wid",
		"wif",
		"wil",
		"wod",
		"wub",
		"wuj",
		"yal",
		"yat",
		"yuk",
		"zac",
		"zaj",
		"zek",
		"zel",
		"zij",
		"zoj",
		"zut",
	],
};
export { wordList };

// words: [
//     "foj",
//     "hon",
//     "tum",
//     "len",
//     "suv",
//     "kam",
//     "res",
//     "kick",
//     "id",
//     "wod",
//     "nej",
//     "sok",
//     "bis",
//     "wem",
//     "av",
//     "jaj",
//     "luj",
//     "wan",
//     "uc",
//     "bas",
//     "bef",
//     "zij",
//     "ven",
//     "wil",
//     "lod",
//     "os",
//     "hic",
//     "vos",
//     "ril",
//     "ves",
//     "jos",
//     "sej",
//     "pac",
//     "ec",
//     "dok",
//     "vut",
//     "vib",
//     "kov",
//     "huv",
//     "seb",
//     "aj",
//     "fav",
//     "wif",
//     "ac",
//     "id",
//     "vep",
//     "sov",
//     "jeb",
//     "feg",
//     "jit",
//     "wub",
//     "doj",
//     "ik",
//     "vus",
//     "nuk",
//     "ul",
//     "zel",
//     "feb",
//     "wuj",
//     "hiz",
//     "min",
//     "ros",
//     "kub",
//     "jaf",
//     "duz",
//     "faj",
//     "ad",
//     "ked",
//     "ig",
//     "el",
//     "loj",
//     "et",
//     "yat",
//     "ol",
//     "tov",
//     "uf",
//     "ral",
//     "ep",
//     "kab",
//     "vif",
//     "tic",
//     "dev",
//     "dop",
//     "zac",
//     "doc",
//     "tik",
//     "sij",
//     "zoj",
//     "mig",
//     "zut",
//     "foj",
//     "ib",
//     "jud",
//     "zek",
//     "vov",
//     "ruz",
//     "huf",
//     "sib",
//     "ak",
//     "jec",
//     "pov",
//     "riz",
//     "hal",
//     "jok",
//     "bel",
//     "ib",
//     "tum",
//     "kaj",
//     "rec",
//     "ut",
//     "nej",
//     "hos",
//     "um",
//     "waf",
//     "joz",
//     "kef",
//     "hod",
//     "dek",
//     "eb",
//     "laj",
//     "nen",
//     "vad",
//     "wid",
//     "wil",
//     "yuk",
//     "nek",
//     "feg",
//     "doz",
//     "un",
//     "tef",
//     "yal",
//     "ep",
//     "vus",
//     "kic",
//     "bal",
//     "pol",
//     "em",
//     "jej",
//     "puf",
//     "nuf",
//     "tum",
//     "uf",
//     "nov",
//     "zaj",
//     "ves",
//     "nej",
//     "ug",
//     "kes",
//     "kub",
//     "los",
// ],
