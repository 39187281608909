import { ColorModeScript } from "@chakra-ui/react";
import React, { StrictMode } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import * as ReactDOM from "react-dom/client";
import Home from "./Home";

import AIMSWebMath from "./AIMSWebMath";
import NCF from "./NCF";
import MFF_1D from "./MFF_1D";
import MFF_T from "./MFF_T";

import AIMSWebELA from "./AIMSWebELA";
import NWF from "./NWF";
import WRF from "./WRF";
import Spelling from "./Spelling";

import "./style.css";

const router = createBrowserRouter([
	{ path: "/", element: <Home /> },
	{ path: "/awm", element: <AIMSWebMath /> },
	{ path: "/awm/ncf", element: <NCF /> },
	{ path: "/awm/mff-1d", element: <MFF_1D /> },
	{ path: "/awm/mff-t", element: <MFF_T /> },
	{ path: "/ela", element: <AIMSWebMath /> },
	{ path: "/awe", element: <AIMSWebELA /> },
	{ path: "/awe/nwf", element: <NWF /> },
	{ path: "/awe/wrf", element: <WRF /> },
	{ path: "/awe/spelling", element: <Spelling /> },
	{ path: "/math", element: <AIMSWebMath /> },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
	<StrictMode>
		<ColorModeScript />
		<RouterProvider router={router} />
	</StrictMode>
);
