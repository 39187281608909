import React, { useState, useRef, useEffect } from "react";
import {
	VStack,
	Text,
	Center,
	HStack,
	Button,
	Image,
	Input,
} from "@chakra-ui/react";
import "./style.css";

import { HomeButton, BackButton } from "./HomeAndBackButtons";
import speakerpng from "./images/1608454_up_volume_icon.png";
import { wordList } from "./Spelling_Wordlist";

export default function Spelling(props) {
	const [answer, setAnswer] = useState("");
	const [canPick, setCanPick] = useState(true);

	const inputBox = useRef();

	function handleKeyUp(e) {
		if (e.key === "Enter") {
			checkRight();
		}
	}

	useEffect(() => {
		pickNewWord();
	}, []);

	function pickNewWord() {
		setCanPick(true);
		let random = wordList["words"].sort(() => 0.5 - Math.random()).slice(0, 1);
		setAnswer(random[0]);
	}

	function checkRight() {
		setCanPick(false);
		if (inputBox.current.value == answer) {
			setRightIndicator(inputBox);
		} else {
			setWrongIndicator(inputBox);
		}

		setTimeout(() => {
			resetIndicator(inputBox);
			inputBox.current.value = "";
			inputBox.current.focus();
			pickNewWord();
		}, 3000);
	}

	function setRightIndicator(aRef) {
		aRef.current.style.outlineStyle = "solid";
		aRef.current.style.outlineColor = "green";
		aRef.current.style.outlineOffset = "0px";
		aRef.current.style.outlineWidth = "10px";
	}

	function setWrongIndicator(aRef) {
		aRef.current.style.outlineStyle = "solid";
		aRef.current.style.outlineColor = "red";
		aRef.current.style.outlineOffset = "0px";
		aRef.current.style.outlineWidth = "10px";
	}

	function resetIndicator(aRef) {
		aRef.current.style.outlineStyle = "none";
	}

	return (
		<>
			<BackButton />
			<HomeButton />
			<Center pt="50px">
				<VStack spacing="10px">
					<Text className="mainTitle">Listen and type the word</Text>
					<Center>
						<Button
							className="voiceButton"
							onClick={() => {
								var msg = new SpeechSynthesisUtterance();
								msg.rate = 0.75;
								msg.text = answer;
								window.speechSynthesis.speak(msg);
							}}
							isDisabled={!canPick}
						>
							<Image
								w="100%"
								h="100%"
								objectFit="contain"
								src={speakerpng}
							></Image>
						</Button>
					</Center>
					<Input
						className="gameInputBox"
						ref={inputBox}
						onKeyUp={handleKeyUp}
					></Input>
					<Button
						className="gameSubmitButton"
						isDisabled={!canPick}
						onClick={checkRight}
					>
						Check Answer
					</Button>
				</VStack>
			</Center>
		</>
	);
}
